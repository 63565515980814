import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;

export const Input = styled.input`
  padding: 5px;
  margin: 5px 0;
  width: 100%;
`;

export const RangeInput = styled(Input)`
  width: 60px;
  margin-right: 5px;
`;

export const RangeLabel = styled.label`
  display: inline-block;
  width: 100px;
  margin-right: 10px;
`;

export const Button = styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
  &:hover {
    opacity: 0.8;
  }
`;