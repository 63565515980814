import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const ScrollableMenu = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  background-color: #f0f0f0;
  padding: 10px 0;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ContentArea = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
`;

export const MenuButton = styled.button`
  background-color: ${props => props.active ? props.theme.colors.primary : 'white'};
  color: ${props => props.active ? 'white' : props.theme.colors.primary};
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  opacity: ${props => props.faded ? 0.5 : 1};
  font-size: 1em;
  border-radius: 5px;
  
  &:hover {
    background-color: ${props => props.active ? props.theme.colors.primary : props.theme.colors.secondary};
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
`;