import axios from '../../../../services/api/axios';
import { handleApiError } from '../../../shared/utils/errorHandler';

export const loginUser = async (username, password) => {
  try {
    console.log('Making login request...');
    const response = await axios.post('/api/users/login', { username, password });
    console.log('Login response:', response);
    
    const { token, user } = response.data.data;
    if (token) {
      console.log('Setting token and user data');
      localStorage.setItem('token', token);
      localStorage.setItem('userData', JSON.stringify({
        id: user.id,
        username: user.username,
        // Add any other necessary user fields
      }));
    } else {
      console.warn('No token in response:', response.data);
    }
    
    return response.data.data;
  } catch (error) {
    console.error('Login error in service:', error);
    throw handleApiError(error);
  }
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userData');
  window.location.href = '/login';
};