import React, { useState, useEffect } from 'react';
import axios from '../../../../services/api/axios';
import { format } from 'date-fns';
import {
  Container,
  ButtonContainer,
  MenuButton,
  TopGrid,
  MiddleGrid,
  BottomGrid,
  Button,
  Divider,
  Typography,
  Overlay,
  FeedContainer,
  CloseButton,
  FeedItem,
  FeedItemInfo,
  DeleteButton
} from './styles';
import {
  getMaxLayers,
  calculateTotalBoxes,
  addPalletToLocation,
  deletePallet
} from '../services/operatorService';

export const FinishMenu = ({ 
  selectedPallets, 
  onFinishPallet, 
  locationId, 
  tomatoType, 
  filterRanges,
  recentlyFinished 
}) => {
  const [showFeed, setShowFeed] = useState(false);
  const [feedPallets, setFeedPallets] = useState([]);
  const [error, setError] = useState(null);
  const [mode, setMode] = useState('Finished');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (showFeed) {
      fetchTodaysPallets();
    }
  }, [showFeed]);

  const getPositionInfo = (position) => {
    const positions = getPositionsForTomatoType(tomatoType);
    return positions.find(pos => pos.label === position);
  };

  const renderPositionButton = (position) => {
    const isSelected = !!selectedPallets[position.label];
    const isRecentlyFinished = recentlyFinished?.[position.label];

    return (
      <Button
        key={position.label}
        onClick={() => handleFinishPallet(position.label)}
        disabled={!isSelected || isSubmitting}
        className={`
          ${isSelected ? 'selected' : ''}
          ${isRecentlyFinished ? 'recently-finished' : ''}
        `}
      >
        {position.label}
      </Button>
    );
  };

  const fetchTodaysPallets = async () => {
    try {
      setError(null);
      const response = await axios.get(`/api/pallets/${locationId}`);
      const today = new Date().toISOString().split('T')[0];
      const todaysPallets = response.data
        .filter(pallet => pallet.addedDate.startsWith(today))
        .sort((a, b) => new Date(b.addedDate) - new Date(a.addedDate));
      setFeedPallets(todaysPallets);
    } catch (error) {
      setError('Failed to fetch today\'s pallets');
    }
  };

  const handleFinishPallet = async (position) => {
    if (isSubmitting || !selectedPallets[position]) return;

    setIsSubmitting(true);
    try {
      const palletData = selectedPallets[position];
      const positionInfo = getPositionInfo(position);
      const processStage = positionInfo?.processStage.toLowerCase() || 'unknown';

      let data = {
        ...palletData,
        addedDate: new Date().toISOString(),
        locationId,
        position,
        processStage,
        isUnfinished: mode === 'Unfinished',
      };

      if (filterRanges?.[processStage]) {
        data.filterRangeMin = filterRanges[processStage].min;
        data.filterRangeMax = filterRanges[processStage].max;
      }

      if (mode === 'Unfinished') {
        const maxLayers = getMaxLayers(palletData.palletType, palletData.boxType);
        const layers = await promptForLayers(maxLayers);
        if (layers === null) {
          setIsSubmitting(false);
          return;
        }

        const additionalBoxes = await promptForAdditionalBoxes();
        if (additionalBoxes === null) {
          setIsSubmitting(false);
          return;
        }

        data = {
          ...data,
          layers: parseInt(layers),
          additionalBoxes: parseInt(additionalBoxes),
          colliCount: calculateTotalBoxes(
            palletData.palletType,
            palletData.boxType,
            parseInt(layers),
            parseInt(additionalBoxes)
          )
        };
      } else {
        const maxLayers = getMaxLayers(palletData.palletType, palletData.boxType);
        data = {
          ...data,
          layers: maxLayers,
          additionalBoxes: 0,
          totalBoxes: calculateTotalBoxes(palletData.palletType, palletData.boxType, maxLayers)
        };
      }

      await addPalletToLocation(data);
      onFinishPallet(position, true);
      if (showFeed) {
        fetchTodaysPallets();
      }
    } catch (error) {
      console.error('Error adding pallet:', error);
      onFinishPallet(position, false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeletePallet = async (palletId) => {
    if (window.confirm('Are you sure you want to delete this pallet?')) {
      try {
        await deletePallet(palletId);
        fetchTodaysPallets();
      } catch (error) {
        setError('Failed to delete pallet');
      }
    }
  };

  const renderFeedOverlay = () => (
    showFeed && (
      <Overlay onClick={() => setShowFeed(false)}>
        <FeedContainer onClick={e => e.stopPropagation()}>
          <CloseButton onClick={() => setShowFeed(false)}>&times;</CloseButton>
          <h3>Today's Pallets</h3>
          {error ? (
            <div>{error}</div>
          ) : feedPallets.length > 0 ? (
            feedPallets.map((pallet) => (
              <FeedItem key={pallet.id}>
                <FeedItemInfo>
                  <div>Position: {pallet.position}</div>
                  <div>Type: {pallet.tomatoType} - {pallet.tomatoOption}</div>
                  <div>Process: {pallet.processStage}</div>
                  <div>Added: {format(new Date(pallet.addedDate), 'HH:mm:ss')}</div>
                </FeedItemInfo>
                <DeleteButton onClick={() => handleDeletePallet(pallet.id)}>
                  Delete
                </DeleteButton>
              </FeedItem>
            ))
          ) : (
            <p>No pallets added today.</p>
          )}
        </FeedContainer>
      </Overlay>
    )
  );

  const renderButtons = () => {
    const positions = getPositionsForTomatoType(tomatoType);
    
    return (
      <>
        <TopGrid>
          {positions.slice(0, 6).map(renderPositionButton)}
        </TopGrid>
        <MiddleGrid>
          {positions.slice(6, 8).map(renderPositionButton)}
        </MiddleGrid>
        <Divider />
        <Typography>Automaat</Typography>
        <BottomGrid>
          {positions.slice(8).map(renderPositionButton)}
        </BottomGrid>
      </>
    );
  };

  return (
    <Container>
      <ButtonContainer>
        <MenuButton onClick={() => setShowFeed(true)}>
          Show Feed
        </MenuButton>
        <MenuButton 
          active={mode === 'Unfinished'}
          onClick={() => setMode(prev => prev === 'Finished' ? 'Unfinished' : 'Finished')}
        >
          {mode}
        </MenuButton>
      </ButtonContainer>
      {renderButtons()}
      {renderFeedOverlay()}
    </Container>
  );
};

// Helper functions would go here or in a separate utils file
const promptForLayers = async (maxLayers) => {
  const layers = prompt(`How many full layers of tomato boxes? (Max: ${maxLayers})`);
  if (!layers || isNaN(layers) || parseInt(layers) > maxLayers) {
    alert("Invalid number of layers");
    return null;
  }
  return layers;
};

const promptForAdditionalBoxes = async () => {
  const boxes = prompt('How many additional boxes?');
  if (!boxes || isNaN(boxes)) {
    alert("Invalid number of additional boxes");
    return null;
  }
  return boxes;
};

const getPositionsForTomatoType = (tomatoType) => {
  return tomatoType === 'Round' 
    ? [
        { label: 'Light A grof +', processStage: 'Light' },
        { label: 'Middle A grof +', processStage: 'Middle' },
        { label: 'Red A grof +', processStage: 'Red' },
        { label: 'Light B', processStage: 'Light' },
        { label: 'Middle B', processStage: 'Middle' },
        { label: 'Red B', processStage: 'Red' },
        { label: 'A fijn Light', processStage: 'Light' },
        { label: 'A fijn Middle', processStage: 'Middle' },
        { label: 'A grof Red', processStage: 'Red' },
        { label: 'A grof Middle', processStage: 'Middle' },
        { label: 'A grof Light', processStage: 'Light' },
      ]
    : [
        { label: 'Light 6 Pack', processStage: 'Light' },
        { label: 'Middle 6 Pack', processStage: 'Middle' },
        { label: 'Red 6 Pack', processStage: 'Red' },
        { label: 'Light B', processStage: 'Light' },
        { label: 'Middle B', processStage: 'Middle' },
        { label: 'Red B', processStage: 'Red' },
        { label: 'A fijn Light', processStage: 'Light' },
        { label: 'A fijn Middle', processStage: 'Middle' },
        { label: 'Red 5 Pack', processStage: 'Red' },
        { label: 'Middle 5 Pack', processStage: 'Middle' },
        { label: 'Light 5 Pack', processStage: 'Light' },
      ];
};