import axios from 'axios';
import { handleApiError } from '../../features/shared/utils/errorHandler';

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? 'https://eyesu.ltd' : '',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

instance.interceptors.response.use(
  response => response,
  error => Promise.reject(handleApiError(error))
);

export default instance;