export const palletTypes = [
  'Pool',
  'Euro Chep',
  'Block Chep',
  'Block',
  'LPR',
  'Euro',
  'Single Use Block',
  'Single Use Euro',
  'Air Plane Pallet',
  'DPA'
];

export const boxTypes = [
  'Sales',
  'Nexture',
  'Van Gelder Low',
  'Van Gelder High',
  'Black Crate Small',
  'Green Crate Large',
  'Black Box',
  'Royal Red',
  'Bella Tondo',
  'Green Crate Small',
  'Novy'
];

export const tomatoOptions = {
  'Round': {
    'A grof +': {},
    'A grof': {},
    'A fijn': {},
    'B': {}
  },
  'Cherry Round': {
    'A': {},
    'B': {},
  },
  'Plum': {
    '6-Pack': {},
    '5-Pack': {},
    'A fijn': {},
    'B': {}
  },
  'Cherry Plum': {
    'A': {},
    'B': {},
  }
};

export const processStages = [
  'unprocessed',
  'in_processing',
  'processed',
  'quality_check',
  'ready_for_shipping'
];

export const orderStatuses = {
  all: 'All Orders',
  unassigned: 'Unassigned',
  assigned: 'Assigned',
  processing: 'In Processing',
  completed: 'Completed',
  shipped: 'Shipped'
};

export const palletStatuses = {
  all: 'All Pallets',
  active: 'Active',
  processing: 'In Processing',
  shipped: 'Shipped'
};

export const dateRangeOptions = {
  all: 'All Time',
  today: 'Today',
  week: 'This Week',
  month: 'This Month',
  custom: 'Custom Range'
};

export const weightRangeOptions = {
  all: 'All Weights',
  under500: 'Under 500kg',
  '500to1000': '500kg - 1000kg',
  over1000: 'Over 1000kg'
};

export const boxCountOptions = {
  all: 'All Box Counts',
  with: 'With Box Count',
  without: 'Without Box Count'
};

export const colliConfigurations = {
  '30x6': 56,  // 46 colli per pallet
  '10x650': 72, // 72 colli per pallet
  '12x500': 75  // 75 colli per pallet
};  
  
export const validatePalletData = (data) => {
    const { palletType, boxType, processStage, tomatoType, tomatoOption } = data;
    return !!(palletType && boxType && processStage && tomatoType && 
      (tomatoOption || data.skipTomatoOption));
  };
  
export const createPalletData = (data) => {
    return {
      palletType: data.palletType,
      boxType: data.boxType,
      processStage: data.processStage,
      tomatoType: data.tomatoType,
      tomatoOption: data.skipTomatoOption ? data.availableOptions[0] : data.tomatoOption,
    };
  };

export const loadSavedSelections = (userId) => {
    try {
      const saved = localStorage.getItem(`palletSelections_${userId}`);
      return saved ? JSON.parse(saved) : {};
    } catch (error) {
      console.error('Error loading saved selections:', error);
      return {};
    }
  };
  
export const saveSelections = (userId, selections) => {
    try {
      localStorage.setItem(`palletSelections_${userId}`, JSON.stringify(selections));
    } catch (error) {
      console.error('Error saving selections:', error);
    }
  };
  
export const loadRecentlyFinished = () => {
    try {
      const saved = localStorage.getItem('recentlyFinishedPallets');
      return saved ? JSON.parse(saved) : {};
    } catch (error) {
      console.error('Error loading recently finished pallets:', error);
      return {};
    }
  };
  
export const saveRecentlyFinished = (pallets) => {
    try {
      // Clean up old entries (older than 10 minutes)
      const now = Date.now();
      const cleaned = Object.entries(pallets).reduce((acc, [key, timestamp]) => {
        if (now - timestamp < 600000) { // 10 minutes
          acc[key] = timestamp;
        }
        return acc;
      }, {});
      
      localStorage.setItem('recentlyFinishedPallets', JSON.stringify(cleaned));
    } catch (error) {
      console.error('Error saving recently finished pallets:', error);
    }
  };