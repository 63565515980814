import React, { useState, useEffect, useContext } from 'react';
import { Container, Button, Input } from './styles';
import { loginUser } from '../services/authService';
import { UserContext } from '../../../../context/UserContext';

export const LoginDesktop = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
  }, []);

  const handleLogin = async () => {
    try {
      setError('');
      console.log('Attempting login...');
      const response = await loginUser(username, password);
      console.log('Login successful:', response);
      
      setUser(response.user);
      window.location.href = '/dashboard';
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message || 'An error occurred during login');
      setPassword('');
    }
  };

  return (
    <Container>
      <h1>Login (Desktop)</h1>
      <Input 
        type="text" 
        value={username} 
        onChange={(e) => setUsername(e.target.value)} 
        placeholder="Username" 
      />
      <Input 
        type="password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
        placeholder="Password" 
      />
      {error && <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}
      <Button onClick={handleLogin}>Login</Button>
    </Container>
  );
};