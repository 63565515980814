import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/GlobalStyle';
import { theme } from './styles/theme';

// Feature imports
import NotificationManager, { initializeNotifications } from './features/shared/components/NotificationManager';
import { LoginDesktop } from './features/auth/components/LoginDesktop';
import { LoginMobile } from './features/auth/components/LoginMobile';
import { MainDashboard } from './features/dashboard/components/MainDashboard';
import { InventoryDashboard } from './features/dashboard/components/InventoryDashboard';
import { OperatorConsole } from './features/operator/components/OperatorConsole';

const App = () => {
  const [notifyRef, setNotifyRef] = useState(null);
  const isAuthenticated = () => {
    return !!localStorage.getItem('token');
  };

  const getLastLocation = () => {
    return localStorage.getItem('lastLocation') || '/dashboard';
  };

  useEffect(() => {
    if (notifyRef) {
      initializeNotifications(notifyRef);
    }
  }, [notifyRef]);

  useEffect(() => {
    localStorage.removeItem('lastLocation');
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <NotificationManager ref={setNotifyRef} />
      <BrowserRouter>
        <Routes>
          {/* Login Routes */}
          <Route 
            path="/login" 
            element={isMobile ? <LoginMobile /> : <LoginDesktop />} 
          />

          {/* Dashboard Routes */}
          <Route path="/dashboard" element={
            isAuthenticated() ? (
              isMobile ? (
                <Navigate to="/operator" replace />
              ) : (
                <MainDashboard />
              )
            ) : (
              <Navigate to="/login" replace />
            )
          } />
          
          <Route path="/dashboard/inventory" element={
            isAuthenticated() ? (
              isMobile ? (
                <Navigate to="/operator" replace />
              ) : (
                <InventoryDashboard />
              )
            ) : (
              <Navigate to="/login" replace />
            )
          } />

          {/* Operator Route */}
          <Route
            path="/operator"
            element={
              isAuthenticated() ? (
                isMobile ? <OperatorConsole /> : <Navigate to="/dashboard" replace />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />

          {/* Root Route */}
          <Route
            path="/"
            element={
              isAuthenticated() ? (
                <Navigate to={getLastLocation()} replace />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;