// src/features/shared/components/AsyncBoundary/index.js
import React, { useState } from 'react';
import { Loader } from 'lucide-react';
import { notify } from '../NotificationManager';

const errorMessages = {
  'Order is already completed': 'This order has already been marked as completed',
  'Pallet is already shipped': 'This pallet has already been shipped',
  'Invalid split amount': 'Please enter a valid amount to split the pallet',
  'Session expired': 'Your session has expired. Please log in again.',
  'Unauthorized': 'You do not have permission to perform this action',
  'Not found': 'The requested resource was not found',
};

export const withAsyncBoundary = (WrappedComponent) => {
  return function AsyncBoundaryWrapper(props) {
    const [loading, setLoading] = useState(false);

    const handleAsyncAction = async (action, successMessage) => {
      setLoading(true);
      try {
        await action();
        if (successMessage) {
          notify(successMessage, 'success');
        }
      } catch (error) {
        console.error('Error in async action:', error);
        const errorMessage = getErrorMessage(error);
        notify(errorMessage, 'error');

        // Handle special cases
        if (error.response?.status === 401) {
          // Session expired - redirect to login
          localStorage.removeItem('token');
          localStorage.removeItem('userData');
          window.location.href = '/login';
          return;
        }
      } finally {
        setLoading(false);
      }
    };

    const getErrorMessage = (error) => {
      // Try to get message from response
      const serverMessage = error.response?.data?.message;
      if (serverMessage && errorMessages[serverMessage]) {
        return errorMessages[serverMessage];
      }

      // Check status code messages
      const statusMessage = error.response?.status && errorMessages[error.response.status];
      if (statusMessage) {
        return statusMessage;
      }

      // Network errors
      if (error.message === 'Network Error') {
        return 'Unable to connect to the server. Please check your internet connection.';
      }

      // Default error message
      return 'An unexpected error occurred. Please try again.';
    };

    return (
      <div className="relative">
        {loading && (
          <div className="absolute inset-0 bg-white/50 flex items-center justify-center z-50">
            <div className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg shadow">
              <Loader className="w-5 h-5 animate-spin" />
              <span>Processing...</span>
            </div>
          </div>
        )}
        <WrappedComponent
          {...props}
          onAsyncAction={handleAsyncAction}
          isLoading={loading}
        />
      </div>
    );
  };
};

export default withAsyncBoundary;