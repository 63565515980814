import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const Title = styled.h2`
  margin-right: 10px;
  color: ${props => props.theme.colors.primary};
`;

export const ChangeButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

export const StatTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

export const TableHeader = styled.th`
  background-color: #f0f0f0;
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #ddd;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

export const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

export const FilterRangesContainer = styled.div`
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const FilterRangeItem = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

export const ProcessStageIndicator = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${props => {
    switch(props.stage) {
      case 'Light': return 'green';
      case 'Middle': return 'orange';
      case 'Red': return 'red';
      default: return 'gray';
    }
  }};
`;