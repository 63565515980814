import axios from '../../../../services/api/axios';

const getBoxesPerLayer = (palletType, boxType) => {
  const config = {
    'Euro Pallet': {
      'default': 8
    },
    'Chep Pallet': {
      'Black Crate Small': 10,
      'Black Box': 8,
      'default': 8
    },
    'Pool Pallet': {
      'Van Gelder High': 6,
      'Van Gelder Low': 8,
      'Green Crate Large': 6,
      'default': 8
    },
    'Light Pallet': {
      'default': 6
    }
  };

  const palletConfig = config[palletType] || { default: 8 };
  return palletConfig[boxType] || palletConfig.default;
};

export const fetchLeaderboard = async () => {
  try {
    const response = await axios.get('/api/users/leaderboard');
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch leaderboard data');
  }
};

export const fetchMapData = async (locationId) => {
  try {
    const response = await axios.get(`/api/map/${locationId}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch map data');
  }
};

export const getColorForValue = (value, filterRanges) => {
  if (value === null) return 'white';
  
  const { light, middle, red } = filterRanges;

  if (value >= light.min) return `rgb(144, 238, 144)`;
  if (value >= middle.min) return `rgb(255, 165, 0)`;
  if (value >= red.min) return `rgb(255, 0, 0)`;

  const gradientPercent = (value - red.min) / (red.max - red.min);
  const r = Math.floor(255 * gradientPercent);
  return `rgb(${r}, 0, 0)`;
};

export const fetchStatistics = async (locationId, timeFrame) => {
  try {
    const startDate = getStartDate(timeFrame);
    const response = await axios.get(`/api/pallets/stats/${locationId}`, {
      params: { startDate: startDate.toISOString() }
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch statistics');
  }
};

export const fetchFilterRanges = async (locationId) => {
  try {
    const response = await axios.get(`/api/settings/${locationId}`);
    return response.data.filterRanges;
  } catch (error) {
    throw new Error('Failed to fetch filter ranges');
  }
};

const getStartDate = (timeFrame) => {
  const now = new Date();
  switch (timeFrame) {
    case 'week':
      return new Date(now.setDate(now.getDate() - 7));
    case 'month':
      return new Date(now.setMonth(now.getMonth() - 1));
    default: // today
      return new Date(now.setHours(0, 0, 0, 0));
  }
};

export const calculateTotalBoxes = (palletType, boxType, layers, additionalBoxes = 0) => {
  const boxesPerLayer = getBoxesPerLayer(palletType, boxType);
  return (boxesPerLayer * layers) + additionalBoxes;
};

export const getMaxLayers = (palletType, boxType) => {
  const config = {
    'Euro Pallet': 15,
    'Chep Pallet': {
      'Black Crate Small': 11,
      'Black Box': 12
    },
    'Pool Pallet': {
      'Van Gelder High': 13,
      'Van Gelder Low': 19,
      'Green Crate Large': 14
    },
    'Light Pallet': 10
  };

  if (typeof config[palletType] === 'number') {
    return config[palletType];
  }

  if (!config[palletType]?.[boxType]) {
    throw new Error(`Unknown combination: ${palletType} - ${boxType}`);
  }

  return config[palletType][boxType];
};

export const addPalletToLocation = async (palletData) => {
  try {
    const response = await axios.post('/api/pallets/add', palletData);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to add pallet: ${error.message}`);
  }
};

export const deletePallet = async (palletId) => {
  try {
    await axios.delete(`/api/pallets/remove/${palletId}`);
  } catch (error) {
    throw new Error(`Failed to delete pallet: ${error.message}`);
  }
};

export const loadSavedSelections = (userId) => {
  try {
    const saved = localStorage.getItem(`palletSelections_${userId}`);
    return saved ? JSON.parse(saved) : {};
  } catch (error) {
    console.error('Error loading saved selections:', error);
    return {};
  }
};

export const saveSelections = (userId, selections) => {
  try {
    localStorage.setItem(`palletSelections_${userId}`, JSON.stringify(selections));
  } catch (error) {
    console.error('Error saving selections:', error);
  }
};

export const loadRecentlyFinished = () => {
  try {
    return JSON.parse(localStorage.getItem('recentlyFinishedPallets')) || {};
  } catch (error) {
    console.error('Error loading recently finished pallets:', error);
    return {};
  }
};

export const saveRecentlyFinished = (data) => {
  try {
    localStorage.setItem('recentlyFinishedPallets', JSON.stringify(data));
  } catch (error) {
    console.error('Error saving recently finished pallets:', error);
  }
};