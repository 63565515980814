import React, { useState, useEffect } from 'react';
import axios from '../../../../services/api/axios';
import { handleApiError } from '../../../shared/utils/errorHandler';
import { formatDate } from '../../../shared/utils/dateFormatter';

export const updatePallet = async (palletId, palletData) => {
  try {
    const response = await axios.put(`/api/pallets/update/${palletId}`, palletData);
    if (!response.data.success) {
      throw new Error(response.data.message || 'Failed to update pallet');
    }
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const fetchPallets = async (locationId, filters = {}) => {
  try {
    const params = new URLSearchParams();
    if (filters.tomatoType) {
      params.append('tomatoType', filters.tomatoType);
    }
    const response = await axios.get(
      `/api/pallets/${locationId}?${params.toString()}`
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const addPallet = async (palletData) => {
  try {
    const response = await axios.post('/api/pallets/add', palletData);
    return response.data;
  } catch (error) {
    throw new Error('Failed to add pallet');
  }
};

// Location Management
export const fetchLocations = async () => {
  try {
    const response = await axios.get('/api/locations');
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch locations');
  }
};

// Export Functions
export const exportToExcel = async (type = 'all') => {
  try {
    const endpoints = {
      all: '/api/pallets/export/excel',
      round: '/api/pallets/export/roundToExcel',
      plum: '/api/pallets/export/plumToExcel'
    };
    
    const response = await axios.get(endpoints[type], { responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${type}_pallets.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    throw new Error(`Failed to export ${type} data`);
  }
};

// Custom Hooks for Dashboard Data
export const useDashboardData = (locationId) => {
  const [data, setData] = useState({
    locations: [],
    pallets: [],
    loading: true,
    error: null
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        const [locationsData, palletsData] = await Promise.all([
          fetchLocations(),
          locationId ? fetchPallets(locationId) : []
        ]);

        setData({
          locations: locationsData,
          pallets: palletsData,
          loading: false,
          error: null
        });
      } catch (error) {
        setData(prev => ({
          ...prev,
          loading: false,
          error: error.message
        }));
      }
    };

    loadData();
  }, [locationId]);

  return data;
};