import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Input } from './styles';
import { loginUser } from '../services/authService';

export const LoginMobile = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await loginUser(username, password);
      navigate('/dashboard');
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Container>
      <h1>Login (Mobile)</h1>
      <Input 
        type="text" 
        value={username} 
        onChange={(e) => setUsername(e.target.value)} 
        placeholder="Username" 
      />
      <Input 
        type="password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
        placeholder="Password" 
      />
      <Button onClick={handleLogin}>Login</Button>
    </Container>
  );
};
