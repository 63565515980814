import React, { useState, useEffect } from 'react';
import { fetchStatistics, fetchFilterRanges } from '../services/operatorService';
import {
  Container,
  Header,
  Title,
  ChangeButton,
  StatTable,
  TableHeader,
  TableRow,
  TableCell,
  FilterRangesContainer,
  FilterRangeItem,
  ProcessStageIndicator
} from './styles';

export const Statistics = ({ locationId }) => {
  const [timeFrame, setTimeFrame] = useState('today');
  const [stats, setStats] = useState([]);
  const [filterRanges, setFilterRanges] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const [statsData, rangesData] = await Promise.all([
          fetchStatistics(locationId, timeFrame),
          fetchFilterRanges(locationId)
        ]);
        setStats(statsData);
        setFilterRanges(rangesData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [timeFrame, locationId]);

  const changeTimeFrame = () => {
    const frames = ['today', 'week', 'month'];
    const currentIndex = frames.indexOf(timeFrame);
    setTimeFrame(frames[(currentIndex + 1) % frames.length]);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container>
      <Header>
        <Title>{timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)}'s Statistics</Title>
        <ChangeButton onClick={changeTimeFrame}>&gt;</ChangeButton>
      </Header>

      {filterRanges && (
        <FilterRangesContainer>
          <h3>Current Filter Ranges</h3>
          {Object.entries(filterRanges).map(([stage, range]) => (
            <FilterRangeItem key={stage}>
              <ProcessStageIndicator stage={stage} />
              {stage}: {range.min.toFixed(2)} - {range.max.toFixed(2)}
            </FilterRangeItem>
          ))}
        </FilterRangesContainer>
      )}

      <StatTable>
        <thead>
          <tr>
            <TableHeader>Process Stage</TableHeader>
            <TableHeader>Tomato Type</TableHeader>
            <TableHeader>Tomato Option</TableHeader>
            <TableHeader>Pallet Type</TableHeader>
            <TableHeader>Box Type</TableHeader>
            <TableHeader>Count</TableHeader>
          </tr>
        </thead>
        <tbody>
          {stats.map((stat, index) => (
            <TableRow key={index}>
              <TableCell>
                <ProcessStageIndicator stage={stat.processStage} />
                {stat.processStage}
              </TableCell>
              <TableCell>{stat.tomatoType}</TableCell>
              <TableCell>{stat.tomatoOption}</TableCell>
              <TableCell>{stat.palletType}</TableCell>
              <TableCell>{stat.boxType}</TableCell>
              <TableCell>{stat.count}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </StatTable>
    </Container>
  );
};