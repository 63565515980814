// src/features/shared/components/NotificationManager/index.js
import React, { useState, useEffect } from 'react';
import { AlertCircle, CheckCircle, X } from 'lucide-react';
import { NotificationContainer, NotificationItem, CloseButton } from './styles';

export const NotificationManager = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const timer = setInterval(() => {
      setNotifications(current => 
        current.filter(n => n.timestamp + n.duration > Date.now())
      );
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const addNotification = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setNotifications(current => [
      ...current,
      { id, message, type, duration, timestamp: Date.now() }
    ]);
  };

  const removeNotification = (id) => {
    setNotifications(current => current.filter(n => n.id !== id));
  };

  return (
    <NotificationContainer>
      {notifications.map(notification => (
        <NotificationItem
          key={notification.id}
          type={notification.type}
        >
          {notification.type === 'error' ? (
            <AlertCircle size={20} />
          ) : (
            <CheckCircle size={20} />
          )}
          <span style={{ flex: 1 }}>{notification.message}</span>
          <CloseButton onClick={() => removeNotification(notification.id)}>
            <X size={16} />
          </CloseButton>
        </NotificationItem>
      ))}
    </NotificationContainer>
  );
};

// Global notification instance
let notifyInstance = null;

export const initializeNotifications = (setterFunction) => {
  notifyInstance = setterFunction;
};

export const notify = (message, type = 'success', duration = 5000) => {
  if (notifyInstance) {
    notifyInstance(message, type, duration);
  }
};

export default NotificationManager;