import React, { useState, useEffect } from 'react';
import { Check, AlertTriangle, ArrowRight, Package } from 'lucide-react';
import { Alert, AlertDescription } from '../../../../components/ui/alert';
import { palletTypes, boxTypes, tomatoOptions } from '../../services/palletService';

export const OrderForm = ({
  onOrderAdded,
  onCancel,
  initialData = null,
  locationId,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    customer_name: initialData?.customer_name || '',
    palletType: initialData?.palletType || '',
    boxType: initialData?.boxType || '',
    processStage: initialData?.processStage || 'unprocessed',
    tomatoType: initialData?.tomatoType || '',
    tomatoOption: initialData?.tomatoOption || '',
    total_pallets: initialData?.total_pallets || 1,
    output_colli: initialData?.output_colli || 0,
    priority: initialData?.priority || 'normal',
    due_date: initialData?.due_date || '',
    notes: initialData?.notes || ''
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const steps = [
    {
      title: 'Customer Details',
      fields: ['customer_name', 'priority', 'due_date']
    },
    {
      title: 'Product Specifications',
      fields: ['tomatoType', 'tomatoOption', 'palletType', 'boxType']
    },
    {
      title: 'Quantity Details',
      fields: ['total_pallets', 'output_colli']
    },
    {
      title: 'Additional Information',
      fields: ['notes', 'processStage']
    }
  ];

  const validateStep = (stepIndex) => {
    const stepFields = steps[stepIndex - 1].fields;
    const newErrors = {};

    stepFields.forEach(field => {
      switch (field) {
        case 'customer_name':
          if (!formData.customer_name) {
            newErrors.customer_name = 'Customer name is required';
          }
          break;
        case 'total_pallets':
          if (!formData.total_pallets || formData.total_pallets < 1) {
            newErrors.total_pallets = 'At least 1 pallet is required';
          }
          break;
        case 'due_date':
          if (formData.due_date) {
            const dueDate = new Date(formData.due_date);
            if (dueDate < new Date()) {
              newErrors.due_date = 'Due date cannot be in the past';
            }
          }
          break;
        case 'output_colli':
          if (formData.output_colli < 0) {
            newErrors.output_colli = 'Output colli cannot be negative';
          }
          break;
        // Add more validations as needed
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep(currentStep)) {
      setCurrentStep(prev => Math.min(prev + 1, steps.length));
    }
  };

  const handlePrevious = () => {
    setCurrentStep(prev => Math.max(prev - 1, 1));
  };

  const handleSubmit = async () => {
    if (!validateStep(currentStep)) {
      return;
    }

    setLoading(true);
    try {
      await onOrderAdded({
        ...formData,
        location_id: locationId,
      });
      setFeedback({
        type: 'success',
        message: 'Order created successfully'
      });
    } catch (error) {
      setFeedback({
        type: 'error',
        message: error.message || 'Failed to create order'
      });
    } finally {
      setLoading(false);
    }
  };

  const renderStepIndicator = () => (
    <div className="flex items-center justify-center mb-8">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div className={`flex items-center ${index + 1 === currentStep ? 'text-blue-600' : 'text-gray-400'}`}>
            <div className={`rounded-full transition duration-500 ease-in-out h-8 w-8 py-1 border-2 ${
              index + 1 === currentStep
                ? 'border-blue-600 text-blue-600'
                : index + 1 < currentStep
                  ? 'border-green-600 bg-green-600 text-white'
                  : 'border-gray-300'
            } flex items-center justify-center`}>
              {index + 1 < currentStep ? (
                <Check className="w-4 h-4" />
              ) : (
                <span>{index + 1}</span>
              )}
            </div>
            <div className="hidden sm:block ml-2">{step.title}</div>
          </div>
          {index < steps.length - 1 && (
            <div className="flex-auto border-t-2 transition duration-500 ease-in-out mx-4 border-gray-300">
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );

  const renderCurrentStep = () => {
    const currentFields = steps[currentStep - 1].fields;

    return (
      <div className="space-y-4">
        {currentFields.map(field => (
          <div key={field} className="form-group">
            <label className="block text-sm font-medium mb-1">
              {field.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
            </label>
            {renderField(field)}
            {errors[field] && (
              <p className="text-red-500 text-sm mt-1">{errors[field]}</p>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderField = (field) => {
    const baseInputClass = "w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500";
    const errorClass = errors[field] ? "border-red-500" : "border-gray-300";
    
    switch (field) {
      case 'customer_name':
        return (
          <input
            type="text"
            value={formData.customer_name}
            onChange={(e) => handleInputChange('customer_name', e.target.value)}
            className={`${baseInputClass} ${errorClass}`}
            placeholder="Enter customer name"
          />
        );

      case 'tomatoType':
        return (
          <select
            value={formData.tomatoType}
            onChange={(e) => handleInputChange('tomatoType', e.target.value)}
            className={`${baseInputClass} ${errorClass}`}
          >
            <option value="">Select Tomato Type</option>
            {Object.keys(tomatoOptions).map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
        );

      case 'tomatoOption':
        return (
          <select
            value={formData.tomatoOption}
            onChange={(e) => handleInputChange('tomatoOption', e.target.value)}
            className={`${baseInputClass} ${errorClass}`}
            disabled={!formData.tomatoType}
          >
            <option value="">Select Tomato Option</option>
            {Object.keys(tomatoOptions[formData.tomatoType] || {}).map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        );

      case 'palletType':
        return (
          <select
            value={formData.palletType}
            onChange={(e) => handleInputChange('palletType', e.target.value)}
            className={`${baseInputClass} ${errorClass}`}
          >
            <option value="">Select Pallet Type</option>
            {palletTypes.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
        );

      case 'boxType':
        return (
          <select
            value={formData.boxType}
            onChange={(e) => handleInputChange('boxType', e.target.value)}
            className={`${baseInputClass} ${errorClass}`}
          >
            <option value="">Select Box Type</option>
            {boxTypes.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
        );

      case 'total_pallets':
        return (
          <input
            type="number"
            value={formData.total_pallets}
            onChange={(e) => handleInputChange('total_pallets', parseInt(e.target.value))}
            min="1"
            max="100"
            className={`${baseInputClass} ${errorClass}`}
            placeholder="Enter total number of pallets"
          />
        );

      case 'output_colli':
        return (
          <input
            type="number"
            value={formData.output_colli}
            onChange={(e) => handleInputChange('output_colli', parseInt(e.target.value))}
            min="0"
            className={`${baseInputClass} ${errorClass}`}
            placeholder="Enter expected output boxes"
          />
        );

      case 'priority':
        return (
          <select
            value={formData.priority}
            onChange={(e) => handleInputChange('priority', e.target.value)}
            className={`${baseInputClass} ${errorClass}`}
          >
            <option value="normal">Normal Priority</option>
            <option value="urgent">Urgent</option>
            <option value="low">Low Priority</option>
          </select>
        );

      case 'due_date':
        return (
          <input
            type="date"
            value={formData.due_date}
            onChange={(e) => handleInputChange('due_date', e.target.value)}
            min={new Date().toISOString().split('T')[0]}
            className={`${baseInputClass} ${errorClass}`}
          />
        );

      case 'processStage':
        return (
          <select
            value={formData.processStage}
            onChange={(e) => handleInputChange('processStage', e.target.value)}
            className={`${baseInputClass} ${errorClass}`}
          >
            <option value="unprocessed">Unprocessed</option>
            <option value="in_processing">In Processing</option>
            <option value="processed">Processed</option>
            <option value="quality_check">Quality Check</option>
            <option value="ready_for_shipping">Ready for Shipping</option>
          </select>
        );

      case 'notes':
        return (
          <textarea
            value={formData.notes}
            onChange={(e) => handleInputChange('notes', e.target.value)}
            className={`${baseInputClass} ${errorClass}`}
            rows="3"
            placeholder="Enter any additional notes or requirements"
          />
        );

      default:
        return null;
    }
  };

  const handleInputChange = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
    // Clear error when field is modified
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow">
      {renderStepIndicator()}

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{steps[currentStep - 1].title}</h2>
        {renderCurrentStep()}
      </div>

      <div className="flex justify-between mt-8">
        <button
          onClick={handlePrevious}
          disabled={currentStep === 1}
          className="px-4 py-2 border rounded-md hover:bg-gray-50 disabled:opacity-50"
        >
          Previous
        </button>

        {currentStep < steps.length ? (
          <button
            onClick={handleNext}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Next
          </button>
        ) : (
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 disabled:opacity-50"
          >
            {loading ? 'Creating Order...' : 'Create Order'}
          </button>
        )}
      </div>

      {feedback && (
        <Alert className={`mt-4 ${feedback.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
          {feedback.type === 'error' ? (
            <AlertTriangle className="h-4 w-4" />
          ) : (
            <Check className="h-4 w-4" />
          )}
          <AlertDescription>{feedback.message}</AlertDescription>
        </Alert>
      )}
    </div>
  );
};

export default OrderForm;