import React, { useState, useEffect, useRef } from 'react';
import { PalletForm } from '../../../shared/components/PalletForm';
import { FinishMenu } from '../FinishMenu';
import { Selection } from '../SelectionMenu';
import { Settings } from '../Settings';
import { Map } from '../Map';
import { Statistics } from '../Statistics';
import {
  loadSavedSelections,
  saveSelections,
  loadRecentlyFinished,
  saveRecentlyFinished
} from '../../../shared/services/palletService';
import { fetchFilterRanges } from '../services/operatorService';
import {
  Container,
  ScrollableMenu,
  ContentArea,
  MenuButton,
  ModalOverlay,
  ModalContent
} from './styles';

export const OperatorConsole = ({ locationId, userId }) => {
  const [selectedPallets, setSelectedPallets] = useState({});
  const [showAddPallet, setShowAddPallet] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [tomatoType, setTomatoType] = useState('Round');
  const [activeMenu, setActiveMenu] = useState('selection');
  const [recentlyFinished, setRecentlyFinished] = useState({});
  const [filterRanges, setFilterRanges] = useState({
    light: { min: 0, max: 0 },
    middle: { min: 0, max: 0 },
    red: { min: 0, max: 0 }
  });
  
  const menuRef = useRef(null);

  useEffect(() => {
    // Load saved data
    setSelectedPallets(loadSavedSelections(userId));
    setRecentlyFinished(loadRecentlyFinished());
    fetchFilterRanges();

    // Clean up old recently finished pallets
    const interval = setInterval(cleanupRecentlyFinished, 60000);
    return () => clearInterval(interval);
  }, [userId]);

  useEffect(() => {
    saveSelections(userId, selectedPallets);
  }, [selectedPallets, userId]);

  const cleanupRecentlyFinished = () => {
    const now = Date.now();
    setRecentlyFinished(prev => {
      const updated = { ...prev };
      let hasChanges = false;
      
      Object.entries(updated).forEach(([key, timestamp]) => {
        if (now - timestamp > 600000) { // 10 minutes
          delete updated[key];
          hasChanges = true;
        }
      });

      if (hasChanges) {
        saveRecentlyFinished(updated);
      }
      return updated;
    });
  };

  const handleScroll = () => {
    if (!menuRef.current) return;

    const menuButtons = menuRef.current.children;
    const containerWidth = menuRef.current.offsetWidth;
    const scrollPosition = menuRef.current.scrollLeft;

    Array.from(menuButtons).forEach(button => {
      const buttonPosition = button.offsetLeft - scrollPosition;
      const buttonWidth = button.offsetWidth;

      button.style.opacity = 
        buttonPosition < 0 || buttonPosition + buttonWidth > containerWidth
          ? "0.5"
          : "1";
    });
  };

  const handlePalletSelect = (position) => {
    setCurrentPosition(position);
    setShowAddPallet(true);
  };

  const handlePalletAdded = (palletData) => {
    setSelectedPallets(prev => ({
      ...prev,
      [currentPosition]: { ...palletData, tomatoType }
    }));
    setShowAddPallet(false);
  };

  const handleFinishPallet = (position, success) => {
    if (success) {
      const now = Date.now();
      setRecentlyFinished(prev => {
        const updated = { ...prev, [position]: now };
        saveRecentlyFinished(updated);
        return updated;
      });
    }
  };

  const toggleTomatoType = () => {
    const newType = tomatoType === 'Round' ? 'Plum' : 'Round';
    if (window.confirm(`Switch to ${newType}? This will clear current ${tomatoType} selections.`)) {
      setTomatoType(newType);
      setSelectedPallets(prev => {
        const newSelections = {};
        Object.entries(prev).forEach(([key, value]) => {
          if (value.tomatoType === newType) {
            newSelections[key] = value;
          }
        });
        return newSelections;
      });
    }
  };

  const renderContent = () => {
    switch (activeMenu) {
      case 'selection':
        return (
          <Selection
            selectedPallets={selectedPallets}
            onPalletSelect={handlePalletSelect}
            tomatoType={tomatoType}
            onToggleTomatoType={toggleTomatoType}
          />
        );
      case 'finish':
        return (
          <FinishMenu 
            selectedPallets={selectedPallets}
            onFinishPallet={handleFinishPallet}
            locationId={locationId}
            tomatoType={tomatoType}
            filterRanges={filterRanges}
            recentlyFinished={recentlyFinished}
          />
        );
      case 'settings':
        return (
          <Settings 
            locationId={locationId}
            onSettingsUpdate={fetchFilterRanges}
          />
        );
      case 'statistics':
        return <Statistics locationId={locationId} />;
      case 'map':
        return <Map locationId={locationId} filterRanges={filterRanges} />;
      default:
        return null;
    }
  };

  return (
    <Container>
      <ScrollableMenu ref={menuRef} onScroll={handleScroll}>
        <MenuButton 
          active={activeMenu === 'selection'}
          onClick={() => setActiveMenu('selection')}
        >
          Selection
        </MenuButton>
        <MenuButton 
          active={activeMenu === 'finish'}
          onClick={() => setActiveMenu('finish')}
        >
          Finish
        </MenuButton>
        <MenuButton 
          active={activeMenu === 'settings'}
          onClick={() => setActiveMenu('settings')}
        >
          Settings
        </MenuButton>
        <MenuButton 
          active={activeMenu === 'statistics'}
          onClick={() => setActiveMenu('statistics')}
        >
          Statistics
        </MenuButton>
        <MenuButton 
          active={activeMenu === 'map'}
          onClick={() => setActiveMenu('map')}
        >
          Map
        </MenuButton>
      </ScrollableMenu>

      <ContentArea>
        {renderContent()}
      </ContentArea>

      {showAddPallet && (
        <ModalOverlay>
          <ModalContent>
            <PalletForm
              onPalletAdded={handlePalletAdded}
              onCancel={() => setShowAddPallet(false)}
              isMobile={true}
              tomatoType={tomatoType}
              locationId={locationId}
              initialData={selectedPallets[currentPosition]}
            />
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};