import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const MenuButton = styled.button`
  background-color: ${props => props.active ? 'white' : props.theme.colors.primary};
  color: ${props => props.active ? props.theme.colors.primary : 'white'};
  border: 2px solid ${props => props.theme.colors.primary};
  padding: 10px 15px;
  cursor: pointer;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  opacity: ${props => props.faded ? 0.5 : 1};
  font-size: 1em;
  border-radius: 5px;
  
  &:hover {
    opacity: 0.8;
  }
`;

export const Grid = styled.div`
  display: grid;
  gap: 15px;
  width: 100%;
`;

export const TopGrid = styled(Grid)`
  grid-template-columns: repeat(3, 1fr);
`;

export const MiddleGrid = styled(Grid)`
  grid-template-columns: repeat(2, 1fr);
  margin-top: 20px;
`;

export const BottomGrid = styled(Grid)`
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;
`;

export const Button = styled.button`
  background-color: ${props => props.selected ? props.theme.colors.primary : 'white'};
  color: ${props => props.selected ? 'white' : props.theme.colors.primary};
  border: 2px solid ${props => props.theme.colors.primary};
  padding: 15px;
  font-size: 14px;
  border-radius: 10px;
  cursor: ${props => props.selected ? 'pointer' : 'not-allowed'};
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100px;
  opacity: ${props => props.selected ? 1 : 0.5};
  
  &:hover {
    opacity: ${props => props.selected ? 0.8 : 0.5};
  }
`;

export const Divider = styled.hr`
  margin: 20px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #eee;
`;

export const Typography = styled.h2`
  text-align: center;
  margin: 20px 0;
  font-size: 24px;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const FeedContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

export const FeedItem = styled.div`
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FeedItemInfo = styled.div`
  flex-grow: 1;
`;

export const DeleteButton = styled.button`
  background-color: #ff4136;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;