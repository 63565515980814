import styled from 'styled-components';

export const NotificationContainer = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 50;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const NotificationItem = styled.div`
  display: flex;
  items-center: center;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  min-width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.type === 'error' ? '#FEE2E2' : '#ECFDF5'};
  border: 1px solid ${props => props.type === 'error' ? '#FCA5A5' : '#6EE7B7'};
  color: ${props => props.type === 'error' ? '#991B1B' : '#065F46'};
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: currentColor;
  opacity: 0.6;
  cursor: pointer;
  padding: 0.25rem;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
  }
`;