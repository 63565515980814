// src/features/operator/components/Map/index.js
import React, { useState, useEffect } from 'react';
import { fetchMapData, getColorForValue } from '../services/operatorService';
import {
  Container,
  MapGrid,
  Cell,
  SectionTitle,
  FilterRangesContainer,
  FilterRangeItem,
  ProcessStageIndicator
} from './styles';

const SECTIONS = {
  'Greenseas': { rows: 1, columns: 2 },
  'Bakker': { rows: 6, columns: 5 },
  'Bakker Extra': { rows: 6, columns: 2 },
  // Add other sections...
};

export const Map = ({ locationId, filterRanges }) => {
  const [mapData, setMapData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadMapData = async () => {
      try {
        setLoading(true);
        const data = await fetchMapData(locationId);
        setMapData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadMapData();
  }, [locationId]);

  const handleCellClick = (section, index, cellData) => {
    if (!cellData) {
      return alert(`Section: ${section}\nPosition: ${index + 1}\nNo pallet data available`);
    }

    alert(
      `Section: ${section}\n` +
      `Position: ${index + 1}\n` +
      `Pallet Type: ${cellData.palletType}\n` +
      `Box Type: ${cellData.boxType}\n` +
      `Tomato Type: ${cellData.tomatoType}\n` +
      `Tomato Option: ${cellData.tomatoOption}\n` +
      `Process Stage: ${cellData.processStage}`
    );
  };

  const renderSection = (sectionName, { rows, columns }) => (
    <div key={sectionName}>
      <SectionTitle>{sectionName}</SectionTitle>
      <MapGrid columns={columns}>
        {Array.from({ length: rows * columns }).map((_, index) => {
          const cellData = mapData[sectionName]?.[index];
          const color = cellData ? getColorForValue(cellData.value, filterRanges) : 'white';
          return (
            <Cell
              key={index}
              color={color}
              onClick={() => handleCellClick(sectionName, index, cellData)}
            >
              {index + 1}
            </Cell>
          );
        })}
      </MapGrid>
    </div>
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container>
      <h2>Storage Map</h2>
      {Object.entries(SECTIONS).map(([name, dimensions]) => 
        renderSection(name, dimensions)
      )}
    </Container>
  );
};