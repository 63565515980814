import styled from 'styled-components';

export const DashboardContainer = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.colors.background};
`;

export const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

export const LocationSelect = styled.select`
  width: 100%;
  max-width: 300px;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 0.5rem;
  background-color: white;
  font-size: 1rem;
  transition: all 0.2s ease;
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.secondary};
    box-shadow: 0 0 0 2px ${props => props.theme.colors.primary}20;
  }
`;

export const TabButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  background-color: ${props => props.active ? props.theme.colors.primary : 'white'};
  color: ${props => props.active ? 'white' : props.theme.colors.text};
  transition: all 0.2s ease;
  border: 1px solid ${props => props.active ? props.theme.colors.primary : '#e5e7eb'};

  &:hover {
    background-color: ${props => props.active ? props.theme.colors.primary : '#f3f4f6'};
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }
`;

export const StatsCard = styled.div`
  background-color: white;
  border-radius: 0.75rem;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
  border: 1px solid #e5e7eb;
  transition: all 0.2s ease;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }

  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    color: ${props => props.theme.colors.text};
    margin-bottom: 1rem;
  }
`;

export const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
`;

export const StatBox = styled.div`
  background-color: #f9fafb;
  padding: 1.25rem;
  border-radius: 0.75rem;
  border: 1px solid #e5e7eb;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f3f4f6;
  }

  h3 {
    font-size: 0.875rem;
    color: #6b7280;
    margin-bottom: 0.5rem;
  }
`;

export const PalletCard = styled.div`
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  padding: 1.25rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: ${props => props.theme.colors.primary};
    opacity: ${props => props.hasOrder ? '1' : '0'};
  }

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }

  h4 {
    font-size: 1rem;
    font-weight: 500;
    color: ${props => props.theme.colors.text};
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 0.875rem;
    color: #6b7280;
    margin-bottom: 0.25rem;
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #e5e7eb;
  border-radius: 4px;
  margin: 0.5rem 0;
  overflow: hidden;

  div {
    height: 100%;
    background-color: ${props => props.theme.colors.primary};
    border-radius: 4px;
    transition: width 0.3s ease;
  }
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.2s ease;
  border: none;

  &:hover {
    background-color: ${props => props.theme.colors.secondary};
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }
`;

export const Modal = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  backdrop-filter: blur(4px);
  transition: all 0.3s ease;
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  width: 100%;
  max-width: 32rem;
  max-height: calc(100vh - 4rem);
  overflow-y: auto;
  position: relative;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease;

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: ${props => props.theme.colors.text};
    margin-bottom: 1rem;
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      opacity: 0.8;
      transform: scale(1.1);
    }
  }
`;

export const LoadingSpinner = styled.div`
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid ${props => props.theme.colors.primary};
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const FilterSection = styled.div`
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  padding: 1.25rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
`;

export const TableContainer = styled.div`
  background-color: white;
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
`;

export const TableHeader = styled.th`
  padding: 0.875rem 1.25rem;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  background-color: ${props => props.theme.colors.primary}05;
  border-bottom: 2px solid ${props => props.theme.colors.primary}10;
  color: ${props => props.theme.colors.text};
  cursor: ${props => props.sortable ? 'pointer' : 'default'};
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${props => props.sortable ? `${props.theme.colors.primary}10` : ''};
  }
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #e5e7eb;
  background-color: ${props => {
    if (props.isShipped) return '#f9fafb';
    if (props.isAssigned) return `${props.theme.colors.primary}05`;
    return 'white';
  }};
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${props => {
      if (props.isShipped) return '#f3f4f6';
      if (props.isAssigned) return `${props.theme.colors.primary}10`;
      return '#f9fafb';
    }};
  }
`;

export const TableCell = styled.td`
  padding: 1rem 1.25rem;
  color: ${props => props.theme.colors.text};
  font-size: 0.875rem;
`;

export const ActionButton = styled.button`
  padding: 0.375rem;
  border-radius: 0.375rem;
  border: none;
  background-color: ${props => {
    switch(props.variant) {
      case 'ship': return '#dcfce7';
      case 'edit': return '#dbeafe';
      case 'delete': return '#fee2e2';
      default: return '#f3f4f6';
    }
  }};
  color: ${props => {
    switch(props.variant) {
      case 'ship': return '#166534';
      case 'edit': return '#1e40af';
      case 'delete': return '#dc2626';
      default: return props.theme.colors.text;
    }
  }};
  transition: all 0.2s ease;
  margin-left: 0.5rem;

  &:hover {
    background-color: ${props => {
      switch(props.variant) {
        case 'ship': return '#bbf7d0';
        case 'edit': return '#bfdbfe';
        case 'delete': return '#fecaca';
        default: return '#e5e7eb';
      }
    }};
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }
`;

export const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: ${props => {
    switch(props.status) {
      case 'shipped': return '#f3f4f6';
      case 'completed': return '#dcfce7';
      case 'assigned': return '#dbeafe';
      default: return '#dbeafe';
    }
  }};
  color: ${props => {
    switch(props.status) {
      case 'shipped': return '#374151';
      case 'completed': return '#166534';
      case 'assigned': return '#1e40af';
      default: return '#1e40af';
    }
  }};
`;

export const ResultCount = styled.div`
  font-size: 0.875rem;
  color: ${props => props.theme.colors.text}80;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
`;

export const FilterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  align-items: start;
`;

export const FilterSelect = styled.select`
  width: 100%;
  padding: 0.625rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  background-color: white;
  color: ${props => props.theme.colors.text};
  font-size: 0.875rem;
  transition: all 0.2s ease;
  height: 2.5rem;
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
    box-shadow: 0 0 0 2px ${props => props.theme.colors.primary}10;
  }
`;