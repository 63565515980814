import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;

export const MapGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  gap: 5px;
  margin-bottom: 20px;
`;

export const Cell = styled.div`
  background-color: ${props => props.color};
  border: 1px solid ${props => props.theme.colors.primary};
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

export const SectionTitle = styled.h3`
  margin-top: 20px;
  font-weight: 600;
  color: ${props => props.theme.colors.primary};
`;

export const FilterRangesContainer = styled.div`
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const FilterRangeItem = styled.div`
  margin-bottom: 10px;
`;

export const ProcessStageIndicator = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${props => {
    switch(props.stage) {
      case 'Light': return 'rgb(144, 238, 144)';
      case 'Middle': return 'rgb(255, 165, 0)';
      case 'Red': return 'rgb(255, 0, 0)';
      default: return 'gray';
    }
  }};
`;