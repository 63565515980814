import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../../context/UserContext';
import { Plus, Package, Boxes, ClipboardList, X, Edit, Truck, CheckCircle2, ArrowLeft, Scissors, FileSpreadsheet } from 'lucide-react';
import axios from '../../../../services/api/axios';
import { withAsyncBoundary } from '../../../shared/components/AsyncBoundary';
import { PalletForm } from '../../../shared/components/PalletForm';
import { OrderForm } from '../../../shared/components/OrderForm';
import { format } from 'date-fns';
import { 
  fetchPallets, 
  addPallet, 
  updatePallet 
} from '../services/dashboardService';
import { 
  palletTypes, 
  boxTypes, 
  tomatoOptions,
  processStages,
  orderStatuses,
  palletStatuses,
  dateRangeOptions,
  weightRangeOptions,
  boxCountOptions
} from '../../../shared/services/palletService';
import {
  DashboardContainer,
  ContentWrapper,
  LocationSelect,
  TabButton,
  StatsCard,
  StatsGrid,
  StatBox,
  PalletCard,
  AddButton,
  Modal,
  ModalContent,
  FilterSection,
  FilterGrid,
  FilterSelect,
  TableContainer,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  ActionButton,
  Badge,
  ResultCount
} from './styles';

export const MainDashboard = withAsyncBoundary(({ onAsyncAction }) => {
  const { user, setUser } = useContext(UserContext);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const [pallets, setPallets] = useState([]);
  const [activeTab, setActiveTab] = useState('overview');
  const [showAddPallet, setShowAddPallet] = useState(false);
  const [showAssignOrder, setShowAssignOrder] = useState(false);
  const [selectedPallet, setSelectedPallet] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedTomatoType, setSelectedTomatoType] = useState('');
  const [showAddOrder, setShowAddOrder] = useState(false);
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [filters, setFilters] = useState({
    tomatoType: selectedTomatoType,
    boxType: '',
    tomatoOption: '', 
    palletType: '',
    orderStatus: 'all', // 'all', 'assigned', 'unassigned'
    palletStatus: 'active', // 'active', 'shipped', 'all'
    dateRange: 'all', // 'today', 'week', 'month', 'all'
    addedBy: '',
    weightRange: 'all' // 'all', 'under500', '500to1000', 'over1000'
  });
  const [sortConfig, setSortConfig] = useState({
    key: 'addedDate',
    direction: 'desc'
  });
  let extraBoxCount = null;  // Define extraBoxCount at the top level

  useEffect(() => {
    const checkUserAndLoadData = async () => {
      console.log('Checking user state:', user);
      // Check if there's a token even if user context is null
      const token = localStorage.getItem('token');
      if (token) {
        try {
          // Attempt to decode token and set user context
          const decodedToken = JSON.parse(atob(token.split('.')[1]));
          console.log('Decoded token:', decodedToken);
          if (decodedToken.id && !user) {
            // If we have a token but no user context, set it
            setUser({
              id: decodedToken.id,
              username: decodedToken.username
            });
          }
          
          // Load locations regardless of user context
          const response = await axios.get(`/api/user-locations/user/${decodedToken.id}`);
          console.log('Location response:', response.data);
          if (response.data.success && response.data.data) {
            setLocations(response.data.data);
            if (response.data.data.length > 0) {
              setSelectedLocation(response.data.data[0]);
            }
          }
        } catch (error) {
          console.error('Error processing token or loading data:', error);
        }
      } else {
        console.log('No token found in localStorage');
      }
    };
  
    checkUserAndLoadData();
  }, [user, setUser]);  // Add setUser to the dependencies array

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        if (user && user.id) {
          console.log('Starting initial data fetch for user:', user.id);
          const response = await axios.get(`/api/user-locations/user/${user.id}`);
          console.log('API Response:', response.data);
          
          if (response.data.success && Array.isArray(response.data.data)) {
            setLocations(response.data.data);
            if (response.data.data.length > 0) {
              setSelectedLocation(response.data.data[0]);
            }
          } else {
            console.warn('Invalid response format:', response.data);
          }
        } else {
          console.warn('No user ID available');
        }
      } catch (error) {
        console.error('Error in initial data fetch:', error);
      }
    };
  
    fetchInitialData();
  }, [user]);

  useEffect(() => {
    if (user) {
      loadUserLocations();
    }
  }, [user]);

  useEffect(() => {
    if (selectedLocation) {
      loadPallets(selectedLocation.id);
      loadOrders(selectedLocation.id);
      loadOverviewStats();
    }
  }, [selectedLocation]);

  const [overviewStats, setOverviewStats] = useState({
    totalKgWarehouse: 0,
    totalKgAssigned: 0,
    shippedToday: 0
  });

  useEffect(() => {
    const loadOverviewStats = async () => {
      if (!selectedLocation?.id) return;
      
      try {
        const today = new Date().toISOString().split('T')[0];
        const [totalResponse, shippedResponse] = await Promise.all([
          axios.get(`/api/pallets/${selectedLocation.id}/total-weight`),
          axios.get(`/api/pallets/${selectedLocation.id}/shipped`, {
            params: { date: today }
          })
        ]);
  
        if (totalResponse.data.success && shippedResponse.data.success) {
          setOverviewStats({
            totalKgWarehouse: totalResponse.data.data.totalInWarehouse || 0,
            totalKgAssigned: totalResponse.data.data.totalAssigned || 0,
            shippedToday: shippedResponse.data.data.count || 0
          });
        }
      } catch (error) {
        console.error('Error loading overview stats:', error);
      }
    };
  
    loadOverviewStats();
  }, [selectedLocation]);

  const handleLocationChange = (e) => {
    const selectedId = e.target.value;
    const location = locations.find(loc => loc.id === parseInt(selectedId));
    setSelectedLocation(location);
    if (location) {
      loadPallets(location.id);
    }
  };

  const handleAddPallet = (palletData) => {
    onAsyncAction(
      async () => {
        await axios.post('/api/pallets/add', {
          ...palletData,
          locationId: selectedLocation.id
        });
        await loadPallets(selectedLocation.id);
        setShowAddPallet(false);
      },
      'Pallet added successfully'
    );
  };

  const handleDeletePallet = (palletId) => {
    if (window.confirm('Are you sure you want to delete this pallet?')) {
      onAsyncAction(
        async () => {
          await axios.delete(`/api/pallets/remove/${palletId}`);
          await loadPallets(selectedLocation.id);
        },
        'Pallet deleted successfully'
      );
    }
  };

  const handlePalletUpdate = async (updatedPalletData) => {
    onAsyncAction(
      async () => {
        const currentUser = user?.username || 'Unknown User';
        const editEntry = {
          editDate: new Date().toISOString(),
          editedBy: currentUser
        };
        
        const updateData = {
          ...updatedPalletData,
          locationId: selectedLocation.id,
          editHistory: [
            ...(selectedPallet.editHistory || []),
            editEntry
          ]
        };
        
        await updatePallet(selectedPallet.id, updateData);
        await loadPallets(selectedLocation.id);
        setShowAddPallet(false);
        setIsEditMode(false);
        setSelectedPallet(null);
      },
      'Pallet updated successfully'
    );
  };

  const handleShipPallet = (palletId) => {
    if (window.confirm('Are you sure you want to mark this pallet as shipped?')) {
      onAsyncAction(
        async () => {
          await axios.put(`/api/pallets/mark-shipped/${palletId}`);
          await loadPallets(selectedLocation.id);
        },
        'Pallet marked as shipped'
      );
    }
  };

  const handleAssignOrder = async (palletId) => {
    onAsyncAction(
      async () => {
        const matchingOrders = orders.filter(order => !order.is_completed);
        
        if (matchingOrders.length === 0) {
          throw new Error('No orders available for assignment');
        }
    
        const orderSelection = prompt(
          'Enter order ID to assign:\n\n' + 
          matchingOrders.map(order => 
            `${order.id}: ${order.customer_name} (${order.assigned_pallets || 0} pallets used)`
          ).join('\n')
        );
    
        if (!orderSelection) return;
    
        const extraBoxes = prompt(
          'Enter number of extra boxes used for processing (leave empty if still processing):'
        );
    
        const extraBoxCount = extraBoxes === '' ? null : parseInt(extraBoxes);
    
        await axios.post('/api/orders/assign-pallet', {
          palletId,
          orderId: orderSelection,
          extraBoxCount
        });
        
        if (extraBoxCount > 0) {
          const orderPallets = pallets.filter(p => p.order_id === orderSelection);
          const palletsWithBoxes = orderPallets.filter(p => p.colliCount);
          
          if (palletsWithBoxes.length > 0) {
            const palletSelection = prompt(
              'Select pallet ID to subtract extra boxes from:\n\n' +
              palletsWithBoxes.map(p => 
                `${p.id}: ${p.tomatoType} - ${p.boxType} (Current boxes: ${p.colliCount})`
              ).join('\n')
            );
    
            if (palletSelection) {
              const selectedPallet = palletsWithBoxes.find(p => p.id === parseInt(palletSelection));
              if (selectedPallet && selectedPallet.colliCount >= extraBoxCount) {
                await axios.put(`/api/pallets/update/${palletSelection}`, {
                  ...selectedPallet,
                  colliCount: selectedPallet.colliCount - extraBoxCount
                });
              }
            }
          }
        }
        
        await loadPallets(selectedLocation.id);
        await loadOrders(selectedLocation.id);
      },
      extraBoxCount !== null ? 
        'Order assigned and marked as completed' : 
        'Pallet assigned to order successfully'
    );
  };

  const handleShipOrder = async (orderId) => {
    const orderToShip = orders.find(o => o.id === orderId);
    
    if (!orderToShip) return;
  
    const shouldShip = window.confirm(
      `Are you sure you want to ship this order?\n\n` +
      `Order: ${orderToShip.customer_name}\n` +
      `Assigned Pallets: ${orderToShip.assigned_pallets}\n` +
      `Total Pallets Required: ${orderToShip.total_pallets}`
    );
  
    if (shouldShip) {
      onAsyncAction(
        async () => {
          await axios.post(`/api/orders/ship/${orderId}`);
          await loadOrders(selectedLocation.id);
          await loadPallets(selectedLocation.id);
        },
        'Order shipped successfully'
      );
    }
  };

  const handleSplitPallet = async (pallet) => {
    const splitColliCount = prompt(`Enter number of colli to split from pallet (max ${pallet.colliCount})`);
    if (!splitColliCount) return;
  
    const keepOrder = window.confirm('Keep order assignment for the split pallet?');
  
    onAsyncAction(
      async () => {
        await axios.post(`/api/pallets/${pallet.id}/split`, {
          splitColliCount: parseInt(splitColliCount),
          keepOrderAssignment: keepOrder
        });
        await loadPallets(selectedLocation.id);
      },
      'Pallet split successfully'
    );
  };
  
  const handleReturnPallet = async (pallet) => {
    if (window.confirm('Return this pallet from production to warehouse?')) {
      onAsyncAction(
        async () => {
          await axios.post(`/api/pallets/${pallet.id}/return`);
          await loadPallets(selectedLocation.id);
        },
        'Pallet returned to warehouse'
      );
    }
  };

  const handleAddOrder = async (orderData) => {
    onAsyncAction(
      async () => {
        await axios.post('/api/orders/create', {
          ...orderData,
          locationId: selectedLocation.id
        });
        await loadOrders(selectedLocation.id);
        setShowAddOrder(false);
      },
      'Order created successfully'
    );
  };

  const handleDeleteOrder = async (orderId) => {
    if (window.confirm('Are you sure you want to delete this order?')) {
      onAsyncAction(
        async () => {
          await axios.delete(`/api/orders/delete/${orderId}`);
          await loadOrders(selectedLocation.id);
        },
        'Order deleted successfully'
      );
    }
  };

  const handleExportExcel = () => {
    onAsyncAction(
      async () => {
        const queryParams = new URLSearchParams({
          tomatoType: filters.tomatoType,
          boxType: filters.boxType,
          palletType: filters.palletType,
          palletStatus: filters.palletStatus
        });
    
        const response = await axios.get(
          `/api/pallets/export/excel?${queryParams.toString()}`,
          { responseType: 'blob' }
        );
    
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'pallets.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      },
      'Export completed successfully'
    );
  };

  const loadPallets = async (locationId, tomatoTypeFilter = selectedTomatoType) => {
    if (!locationId) {
      setPallets([]);
      return;
    }
    
    await onAsyncAction(async () => {
      const response = await axios.get(`/api/pallets/${locationId}`);
      if (response.data.success && response.data.data) {
        const allPallets = response.data.data.map(pallet => ({
          ...pallet,
          editHistory: pallet.editHistory ? 
            (typeof pallet.editHistory === 'string' ? 
              JSON.parse(pallet.editHistory) : 
              pallet.editHistory) : 
            []
        }));
        
        const filteredPallets = tomatoTypeFilter ? 
          allPallets.filter(pallet => pallet.tomatoType === tomatoTypeFilter) :
          allPallets;
          
        const sortedPallets = filteredPallets.sort((a, b) => 
          new Date(b.addedDate) - new Date(a.addedDate)
        );
        
        setPallets(sortedPallets);
      }
    });
  };

  const loadOrders = async (locationId) => {
    if (!locationId) return;
    
    await onAsyncAction(async () => {
      const response = await axios.get(`/api/orders/location/${locationId}`);
      if (response.data.success) {
        setOrders(response.data.data);
      }
    });
  };

  const loadUserLocations = async () => {
    await onAsyncAction(async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }
  
      if (user && user.id) {
        const response = await axios.get(`/api/user-locations/user/${user.id}`);
        if (response.data.success && response.data.data) {
          setLocations(response.data.data);
          if (response.data.data.length > 0) {
            setSelectedLocation(response.data.data[0]);
          }
        }
      }
    });
  };

  const loadOverviewStats = async () => {
    if (!selectedLocation?.id) return;
    
    await onAsyncAction(async () => {
      const today = new Date().toISOString().split('T')[0];
      const [totalResponse, shippedResponse] = await Promise.all([
        axios.get(`/api/pallets/${selectedLocation.id}/total-weight`),
        axios.get(`/api/pallets/${selectedLocation.id}/shipped`, {
          params: { date: today }
        })
      ]);

      if (totalResponse.data.success && shippedResponse.data.success) {
        setOverviewStats({
          totalKgWarehouse: totalResponse.data.data.totalInWarehouse || 0,
          totalKgAssigned: totalResponse.data.data.totalAssigned || 0,
          shippedToday: shippedResponse.data.data.count || 0
        });
      }
    });
  };

  const handleEditClick = (pallet) => {
    setSelectedPallet(pallet);
    setIsEditMode(true);
    setShowAddPallet(true);
  };

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const renderOverview = () => (
    <>
    <StatsCard>
      <StatsGrid>
        <StatBox>
          <h3>Warehouse Stock</h3>
          <p className="text-2xl font-bold">{pallets.length}</p>
          <p className="text-lg">Total Pallets</p>
          <p className="text-xl font-semibold mt-2">
            {overviewStats.totalKgWarehouse.toLocaleString()} kg
          </p>
          <p className="text-gray-600">Total Weight</p>
        </StatBox>
        
        <StatBox>
          <h3>Order Assignment</h3>
          <p className="text-2xl font-bold">{orders.length}</p>
          <p className="text-lg">Active Orders</p>
          <p className="text-xl font-semibold mt-2">
            {overviewStats.totalKgAssigned.toLocaleString()} kg
          </p>
          <p className="text-gray-600">Assigned to Orders</p>
        </StatBox>
        
        <StatBox>
          <h3>Today's Activity</h3>
          <p className="text-2xl font-bold">{overviewStats.shippedToday}</p>
          <p className="text-lg">Pallets Shipped</p>
          <div className="mt-2">
            {Object.entries(
              pallets.reduce((acc, pallet) => {
                if (pallet.addedDate?.startsWith(new Date().toISOString().split('T')[0])) {
                  acc[pallet.tomatoType] = (acc[pallet.tomatoType] || 0) + 1;
                }
                return acc;
              }, {})
            ).map(([type, count]) => (
              <div key={type} className="flex justify-between text-sm">
                <span>{type}:</span>
                <span>{count} added</span>
              </div>
            ))}
          </div>
        </StatBox>
      </StatsGrid>
    </StatsCard>
      <StatsCard>
        <StatsGrid>
          <StatBox>
            <h3>Total Pallets</h3>
            <p className="text-2xl font-bold">{pallets.length}</p>
          </StatBox>
          <StatBox>
            <h3>Active Orders</h3>
            <p className="text-2xl font-bold">{orders.length}</p>
          </StatBox>
          <StatBox>
            <h3>By Type</h3>
            <div>
              {Object.entries(
                pallets.reduce((acc, pallet) => {
                  acc[pallet.tomatoType] = (acc[pallet.tomatoType] || 0) + 1;
                  return acc;
                }, {})
              ).map(([type, count]) => (
                <div key={type} className="flex justify-between">
                  <span>{type}:</span>
                  <span>{count}</span>
                </div>
              ))}
            </div>
          </StatBox>
        </StatsGrid>
      </StatsCard>
  
      <StatsCard>
        <h3>Recent Activity</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {pallets
            .sort((a, b) => new Date(b.addedDate) - new Date(a.addedDate))
            .slice(0, 6)
            .map(pallet => (
              <PalletCard key={pallet.id}>
                <div>
                  <h4 className="text-lg font-medium">{pallet.tomatoType} - {pallet.tomatoOption}</h4>
                  <div className="grid grid-cols-2 gap-2 mt-2">
                    <div>
                      <p className="text-gray-500">Box Type:</p>
                      <p>{pallet.boxType}</p>
                    </div>
                    <div>
                      <p className="text-gray-500">Pallet Type:</p>
                      <p>{pallet.palletType}</p>
                    </div>
                    {pallet.colliCount && (
                      <div>
                        <p className="text-gray-500">Boxes:</p>
                        <p>{pallet.colliCount}</p>
                      </div>
                    )}
                    {pallet.weight && (
                      <div>
                        <p className="text-gray-500">Weight:</p>
                        <p>{pallet.weight}kg</p>
                      </div>
                    )}
                  </div>
                  {pallet.orderCustomer && (
                    <div className="mt-2">
                      <Badge status={pallet.order_is_completed ? 'completed' : 'assigned'}>
                        Order: {pallet.orderCustomer}
                      </Badge>
                    </div>
                  )}
                  <div className="text-sm text-gray-500 mt-2">
                    <p>Added: {new Date(pallet.addedDate).toLocaleString()}</p>
                    <p>By: {pallet.userName}</p>
                  </div>
                </div>
              </PalletCard>
            ))}
        </div>
      </StatsCard>
    </>
  );

  const renderOrders = () => (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold">Orders</h3>
        <AddButton onClick={() => setShowAddOrder(true)}>
          <Plus size={16} />
          Add Order
        </AddButton>
      </div>
  
      {orders.map(order => (
        <PalletCard key={order.id}>
        <div>
          <h4 className="text-lg font-medium">{order.customer_name}</h4>
          {order.is_completed && (
            <Badge status="completed">Completed</Badge>
          )}
          <p>Type: {order.tomatoType} - {order.tomatoOption}</p>
          <p>Box: {order.boxType}</p>
          <p>Pallet Type: {order.palletType}</p>
          <div className="font-medium mt-2">
            <p>Order size: {order.total_pallets} pallets</p>
            <p>Input pallets: {order.assigned_pallets || 0}</p>
            <p>Output Colli: {order.completed_colli} / {order.output_colli}</p>
            <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
              <div 
                className="bg-blue-600 h-2.5 rounded-full" 
                style={{ width: `${order.colli_completion}%` }}
              ></div>
            </div>
            {order.extra_boxes !== null && (
              <p className="text-green-600">
                Additional boxes processed: {order.extra_boxes}
              </p>
            )}
          </div>
        </div>
          <div className="flex gap-2">
            {!order.is_completed && (
              <>
                <button
                onClick={() => handleShipOrder(order.id)}
                className="text-green-500 hover:text-green-600"
                >
                <Truck size={20} />
                </button>
              <button
                onClick={() => {
                  setSelectedOrder(order);
                  setShowAddOrder(true);
                }}
                className="text-blue-500 hover:text-blue-600"
              >
                <Edit size={20} />
              </button>
            <button
              onClick={() => handleDeleteOrder(order.id)}
              className="text-red-500 hover:text-red-600"
            >
              <X size={20} />
            </button>
            </>
            )}
          </div>
        </PalletCard>
      ))}
    </div>
  );

  const renderPallets = () => {
    const filterPallets = (pallet) => {
      if (filters.tomatoType && pallet.tomatoType !== filters.tomatoType) return false;
      if (filters.tomatoOption && pallet.tomatoOption !== filters.tomatoOption) return false;
      if (filters.boxType && pallet.boxType !== filters.boxType) return false;
      if (filters.palletType && pallet.palletType !== filters.palletType) return false;
      
      // Order status filtering
      if (filters.orderStatus === 'assigned' && !pallet.orderCustomer) return false;
      if (filters.orderStatus === 'unassigned' && pallet.orderCustomer) return false;
      if (filters.orderStatus === 'completed' && !pallet.order_is_completed) return false;
      if (filters.orderStatus === 'processing' && pallet.order_is_completed) return false;
  
      // Pallet status filtering
      if (filters.palletStatus !== 'all' && pallet.status !== filters.palletStatus) return false;
      
      if (filters.addedBy && pallet.userName !== filters.addedBy) return false;
  
      // Date filtering
      const palletDate = new Date(pallet.addedDate);
      const today = new Date();
      switch (filters.dateRange) {
        case 'today':
          if (palletDate.toDateString() !== today.toDateString()) return false;
          break;
        case 'week':
          const weekAgo = new Date(today);
          weekAgo.setDate(today.getDate() - 7);
          if (palletDate < weekAgo) return false;
          break;
        case 'month':
          const monthAgo = new Date(today);
          monthAgo.setMonth(today.getMonth() - 1);
          if (palletDate < monthAgo) return false;
          break;
      }
  
      // Weight filtering
      const weight = parseFloat(pallet.weight);
        switch (filters.weightRange) {
          case 'under500':
            if (!weight || weight >= 500) return false;
            break;
          case '500to1000':
            if (!weight || weight < 500 || weight > 1000) return false;
            break;
          case 'over1000':
            if (!weight || weight <= 1000) return false;
            break;
        }

      // Order status filtering
      switch (filters.orderStatus) {
        case 'unassigned':
          if (pallet.orderCustomer) return false;
          break;
        case 'assigned':
          if (!pallet.orderCustomer || pallet.order_is_completed || pallet.status === 'shipped') return false;
          break;
        case 'processing':
          if (!pallet.orderCustomer || pallet.order_is_completed || pallet.status === 'shipped') return false;
          break;
        case 'completed':
          if (!pallet.order_is_completed || pallet.status === 'shipped') return false;
          break;
        case 'shipped':
          if (pallet.status !== 'shipped') return false;
          break;
      }

      // Pallet status filtering
      if (filters.palletStatus !== 'all' && pallet.status !== filters.palletStatus) return false;

    
      // Box count filtering
      switch (filters.boxStatus) {
        case 'with':
          if (!pallet.colliCount) return false;
          break;
        case 'without':
          if (pallet.colliCount) return false;
          break;
      }
  
      // If pallet passes all filters, return true
      return true;
    };
  
    const filteredAndSortedPallets = [...pallets]
      .filter(filterPallets)
      .sort((a, b) => {
        if (!a[sortConfig.key]) return 1;
        if (!b[sortConfig.key]) return -1;
        
        const compareResult = a[sortConfig.key].toString().localeCompare(b[sortConfig.key].toString());
        return sortConfig.direction === 'asc' ? compareResult : -compareResult;
      });
  
      return (
        <div className="space-y-4">
           <div className="flex justify-between items-center mb-4">
              <div className="flex items-center gap-4">
                <h3 className="text-xl font-semibold">Pallets</h3>
                <AddButton onClick={() => setShowAddPallet(true)}>
                  <Plus size={16} />
                  Add Pallet
                </AddButton>
              </div>
              <AddButton 
                onClick={() => handleExportExcel()}
                className="bg-green-600 hover:bg-green-700"
              >
                <FileSpreadsheet size={16} />
                Export Excel
              </AddButton>
            </div>
          <FilterSection>
            <FilterGrid>
            <FilterSelect
                value={filters.tomatoType}
                onChange={(e) => setFilters({...filters, tomatoType: e.target.value})}
              >
                <option value="">All Tomato Types</option>
                {Object.keys(tomatoOptions).map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </FilterSelect>

              <FilterSelect
                value={filters.boxType}
                onChange={(e) => setFilters({...filters, boxType: e.target.value})}
              >
                <option value="">All Box Types</option>
                {boxTypes.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </FilterSelect>

              <FilterSelect
                value={filters.palletType}
                onChange={(e) => setFilters({...filters, palletType: e.target.value})}
              >
                <option value="">All Pallet Types</option>
                {palletTypes.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </FilterSelect>

              <FilterSelect
                value={filters.orderStatus}
                onChange={(e) => setFilters({...filters, orderStatus: e.target.value})}
              >
                {Object.entries(orderStatuses).map(([value, label]) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </FilterSelect>

              <FilterSelect
                value={filters.palletStatus}
                onChange={(e) => setFilters({...filters, palletStatus: e.target.value})}
              >
                {Object.entries(palletStatuses).map(([value, label]) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </FilterSelect>

              <FilterSelect
                value={filters.dateRange}
                onChange={(e) => setFilters({...filters, dateRange: e.target.value})}
              >
                {Object.entries(dateRangeOptions).map(([value, label]) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </FilterSelect>

              <FilterSelect
                value={filters.weightRange}
                onChange={(e) => setFilters({...filters, weightRange: e.target.value})}
              >
                {Object.entries(weightRangeOptions).map(([value, label]) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </FilterSelect>

              <FilterSelect
                value={filters.boxStatus}
                onChange={(e) => setFilters({...filters, boxStatus: e.target.value})}
              >
                {Object.entries(boxCountOptions).map(([value, label]) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </FilterSelect>
            </FilterGrid>
          </FilterSection>
                
          <ResultCount>
            {filters.palletStatus === 'shipped' 
              ? `Showing ${filteredAndSortedPallets.length} shipped pallets`
              : `Showing ${filteredAndSortedPallets.length} active pallets in inventory${
                  pallets.length > filteredAndSortedPallets.length 
                    ? ` (${pallets.length - filteredAndSortedPallets.length} filtered or shipped)`
                    : ''
                }`
            }
          </ResultCount>
      
          <TableContainer>
            <Table>
            <thead>
              <tr>
                <TableHeader sortable onClick={() => requestSort('tomatoType')}>
                  Type/Option
                </TableHeader>
                <TableHeader sortable onClick={() => requestSort('boxType')}>
                  Box/Pallet
                </TableHeader>
                <TableHeader sortable onClick={() => requestSort('processStage')}>
                  Process Stage
                </TableHeader>
                <TableHeader sortable onClick={() => requestSort('colliCount')}>
                  Boxes
                </TableHeader>
                <TableHeader sortable onClick={() => requestSort('weight')}>
                  Weight
                </TableHeader>
                <TableHeader sortable onClick={() => requestSort('status')}>
                  Status
                </TableHeader>
                <TableHeader sortable onClick={() => requestSort('orderCustomer')}>
                  Order
                </TableHeader>
                <TableHeader>Assign to Order</TableHeader>
                <TableHeader sortable onClick={() => requestSort('addedDate')}>
                  Added
                </TableHeader>
                <TableHeader>Actions</TableHeader>
              </tr>
            </thead>
              <tbody>
                {filteredAndSortedPallets.map(pallet => (
                  <TableRow key={pallet.id}>
                  <TableCell>
                    <div className="font-medium">{pallet.tomatoType}</div>
                    <div className="text-gray-500">{pallet.tomatoOption}</div>
                  </TableCell>
                  <TableCell>
                    <div>{pallet.boxType}</div>
                    <div className="text-gray-500">{pallet.palletType}</div>
                  </TableCell>
                  <TableCell>
                    {pallet.processStage?.charAt(0).toUpperCase() + pallet.processStage?.slice(1).replace(/_/g, ' ')}
                  </TableCell>
                  <TableCell>
                  <TableCell>
                    {pallet.colli_count ? `${pallet.colli_count} boxes` : '-'}
                  </TableCell>
                  </TableCell>
                  <TableCell>{pallet.weight ? `${pallet.weight}kg` : '-'}</TableCell>
                  <TableCell>
                    <Badge status={pallet.status}>
                      {pallet.status?.charAt(0).toUpperCase() + pallet.status?.slice(1)}
                    </Badge>
                  </TableCell>
                  <TableCell>
                    {pallet.orderCustomer && (
                      <div>
                        <Badge status={pallet.status === 'shipped' ? 'shipped' : 'assigned'}>
                          {pallet.orderCustomer}
                        </Badge>
                        {pallet.order_is_completed && (
                          <Badge status="completed" className="ml-2">
                            Completed
                          </Badge>
                          )}
                        </div>
                      )}
                      </TableCell>
                      <TableCell>
                        <button 
                          onClick={() => handleAssignOrder(pallet.id)}
                          className="text-blue-600 hover:text-blue-900"
                        >
                          Assign to Order
                        </button>
                    </TableCell>
                    <TableCell>
                      <div>{format(new Date(pallet.addedDate), 'MMM d, yyyy')}</div>
                      <div className="text-gray-500 text-xs">By: {pallet.addedBy}</div>
                      {pallet.status === 'shipped' && (
                        <div className="text-gray-500 text-xs mt-1">
                          Shipped: {format(new Date(pallet.shipped_date), 'MMM d, yyyy')}
                          <br />
                          By: {pallet.shipped_by}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="flex justify-end">
                        {pallet.status !== 'shipped' && (
                          <>
                            <ActionButton variant="edit" onClick={() => handleEditClick(pallet)}>
                              <Edit size={16} />
                            </ActionButton>
                            {pallet.order_id && (
                              <ActionButton variant="return" onClick={() => handleReturnPallet(pallet)}>
                                <ArrowLeft size={16} />
                              </ActionButton>
                            )}
                            {pallet.colliCount > 0 && (
                              <ActionButton variant="split" onClick={() => handleSplitPallet(pallet)}>
                                <Scissors size={16} />
                              </ActionButton>
                            )}
                            <ActionButton variant="ship" onClick={() => handleShipPallet(pallet.id)}>
                              <Truck size={16} />
                            </ActionButton>
                            <ActionButton variant="delete" onClick={() => handleDeletePallet(pallet.id)}>
                              <X size={16} />
                            </ActionButton>
                          </>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        </div>
      );
  };

  return (
    <DashboardContainer>
      <ContentWrapper>
        <LocationSelect
          value={selectedLocation?.id || ''}
          onChange={handleLocationChange}
        >
          <option value="">Select Location</option>
          {locations.map(loc => (
            <option key={loc.id} value={loc.id}>
              {loc.name}
            </option>
          ))}
        </LocationSelect>

        <div className="flex gap-4 mb-6">
          <TabButton
            active={activeTab === 'overview'}
            onClick={() => setActiveTab('overview')}
          >
            <Package size={20} />
            Overview
          </TabButton>
          <TabButton
            active={activeTab === 'orders'}
            onClick={() => setActiveTab('orders')}
          >
            <ClipboardList size={20} />
            Orders
          </TabButton>
          <TabButton
            active={activeTab === 'pallets'}
            onClick={() => setActiveTab('pallets')}
          >
            <Boxes size={20} />
            Pallets
          </TabButton>
        </div>

        <div>
          {activeTab === 'overview' && renderOverview()}
          {activeTab === 'orders' && renderOrders()}
          {activeTab === 'pallets' && renderPallets()}
        </div>

        {showAddPallet && (
          <Modal>
            <ModalContent>
              <div className="flex justify-between items-center mb-4">
                <h3>{isEditMode ? 'Edit Pallet' : 'Add New Pallet'}</h3>
                <button onClick={() => {
                  setShowAddPallet(false);
                  setIsEditMode(false);
                  setSelectedPallet(null);
                }}>
                  <X size={20} />
                </button>
              </div>
              <PalletForm
                onPalletAdded={isEditMode ? handlePalletUpdate : handleAddPallet}
                onCancel={() => {
                  setShowAddPallet(false);
                  setIsEditMode(false);
                  setSelectedPallet(null);
                }}
                locationId={selectedLocation?.id}
                tomatoType={selectedTomatoType}
                initialData={selectedPallet}
                isEditMode={isEditMode}
              />
            </ModalContent>
          </Modal>
        )}

        {showAddOrder && (
          <Modal>
            <ModalContent>
              <div className="flex justify-between items-center mb-4">
                <h3>{selectedOrder ? 'Edit Order' : 'Add New Order'}</h3>
                <button onClick={() => {
                  setShowAddOrder(false);
                  setSelectedOrder(null);
                }}>
                  <X size={20} />
                </button>
              </div>
              <OrderForm
                onOrderAdded={handleAddOrder}
                onCancel={() => {
                  setShowAddOrder(false);
                  setSelectedOrder(null);
                }}
                locationId={selectedLocation?.id}
                initialData={selectedOrder}
              />
            </ModalContent>
          </Modal>
        )}
      </ContentWrapper>
    </DashboardContainer>
  );
});

export default MainDashboard;