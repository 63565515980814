import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Alert, AlertDescription } from '../../../../components/ui/alert';
import { UserContext } from '../../../../context/UserContext';
import { Check, AlertTriangle, Loader } from 'lucide-react';
import { 
  palletTypes, 
  boxTypes, 
  tomatoOptions, 
  processStages 
} from '../../services/palletService';

export const PalletForm = ({
  onPalletAdded,
  onCancel,
  tomatoType,
  defaultProcessStage = 'unprocessed',
  initialData = null,
  locationId,
}) => {
  const { user } = useContext(UserContext);
  const [formData, setFormData] = useState({
    palletType: initialData?.palletType || '',
    boxType: initialData?.boxType || '',
    processStage: initialData?.processStage || defaultProcessStage,
    tomatoOption: initialData?.tomatoOption || '',
    tomatoType: initialData?.tomatoType || tomatoType || 'Round',
    weight: initialData?.weight || '',
    order_id: initialData?.order_id || '',
    quantity: initialData?.quantity || 1,
    colliCount: initialData?.colliCount || '',
  });

  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [errors, setErrors] = useState({});
  const [availableOrders, setAvailableOrders] = useState([]);

  // Validation rules
  const validateField = async (name, value) => {
    // Local quick validation for immediate feedback
    const quickValidate = () => {
      switch (name) {
        case 'weight':
          if (value && (value < 0 || value > 2000)) {
            return 'Weight must be between 0 and 2000 kg';
          }
          break;
        case 'colliCount':
          if (value && (value < 0 || value > 200)) {
            return 'Box count must be between 0 and 200';
          }
          break;
        case 'quantity':
          if (value < 1 || value > 50) {
            return 'Quantity must be between 1 and 50';
          }
          break;
      }
      return '';
    };

    // Quick local validation first
    const quickError = quickValidate();
    if (quickError) {
      return quickError;
    }

    // Only proceed with API validation if we pass quick validation
    try {
      const response = await axios.post('/api/pallets/validate', {
        ...formData,
        [name]: value,
        locationId
      });
      
      if (!response.data.valid && response.data.errors[name]) {
        return response.data.errors[name][0];
      }
    } catch (error) {
      console.error('Validation error:', error);
      return 'Failed to validate field';
    }
    
    return '';
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    
    setErrors(prev => ({
      ...prev,
      [name]: error
    }));

    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    // Validate all fields
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      const error = validateField(key, formData[key]);
      if (error) newErrors[key] = error;
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setFeedback({
        type: 'error',
        message: 'Please correct the errors before submitting'
      });
      return;
    }

    setLoading(true);
    try {
      await onPalletAdded({
        ...formData,
        locationId,
        userName: user.username,
        user_id: user.id,
      });
      setFeedback({
        type: 'success',
        message: 'Pallet added successfully'
      });
    } catch (error) {
      setFeedback({
        type: 'error',
        message: error.message || 'Failed to add pallet'
      });
    } finally {
      setLoading(false);
    }
  };

  const renderFeedback = () => {
    if (!feedback) return null;
    
    return (
      <Alert className={`mb-4 ${feedback.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
        {feedback.type === 'error' ? <AlertTriangle className="h-4 w-4" /> : <Check className="h-4 w-4" />}
        <AlertDescription>
          {feedback.message}
        </AlertDescription>
      </Alert>
    );
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow">
      {renderFeedback()}
      
      <div className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Tomato Type Selection */}
          <div className="form-group">
            <label className="block text-sm font-medium mb-1">Tomato Type</label>
            <select
              name="tomatoType"
              value={formData.tomatoType}
              onChange={handleInputChange}
              className="w-full p-2 border rounded border-gray-300"
            >
              <option value="">Select Tomato Type</option>
              {Object.keys(tomatoOptions).map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>

          {/* Tomato Option Selection */}
          {Object.keys(tomatoOptions[formData.tomatoType] || {}).length > 0 && (
            <div className="form-group">
              <label className="block text-sm font-medium mb-1">Tomato Option</label>
              <select
                name="tomatoOption"
                value={formData.tomatoOption}
                onChange={handleInputChange}
                className="w-full p-2 border rounded border-gray-300"
              >
                <option value="">Select Tomato Option</option>
                {Object.keys(tomatoOptions[formData.tomatoType] || {}).map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>
          )}

          {/* Pallet Type Selection */}
          <div className="form-group">
            <label className="block text-sm font-medium mb-1">Pallet Type</label>
            <select
              name="palletType"
              value={formData.palletType}
              onChange={handleInputChange}
              className="w-full p-2 border rounded border-gray-300"
            >
              <option value="">Select Pallet Type</option>
              {palletTypes.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>

          {/* Box Type Selection */}
          <div className="form-group">
            <label className="block text-sm font-medium mb-1">Box Type</label>
            <select
              name="boxType"
              value={formData.boxType}
              onChange={handleInputChange}
              className="w-full p-2 border rounded border-gray-300"
            >
              <option value="">Select Box Type</option>
              {boxTypes.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>

          {/* Weight Input */}
          <div className="form-group">
            <label className="block text-sm font-medium mb-1">Weight (kg)</label>
            <input
              type="number"
              name="weight"
              value={formData.weight}
              onChange={handleInputChange}
              className={`w-full p-2 border rounded ${errors.weight ? 'border-red-500' : 'border-gray-300'}`}
              placeholder="Enter pallet weight"
              step="0.1"
              min="0"
              max="2000"
            />
            {errors.weight && (
              <p className="text-red-500 text-sm mt-1">{errors.weight}</p>
            )}
          </div>

          {/* Quantity Input */}
          <div className="form-group">
            <label className="block text-sm font-medium mb-1">Pallet Count</label>
            <input
              type="number"
              name="quantity"
              value={formData.quantity}
              onChange={handleInputChange}
              className={`w-full p-2 border rounded ${errors.quantity ? 'border-red-500' : 'border-gray-300'}`}
              placeholder="Number of pallets"
              min="1"
              max="50"
            />
            {errors.quantity && (
              <p className="text-red-500 text-sm mt-1">{errors.quantity}</p>
            )}
          </div>

          {/* Box Count Input */}
          <div className="form-group">
            <label className="block text-sm font-medium mb-1">Box Count (Colli)</label>
            <input
              type="number"
              name="colliCount"
              value={formData.colliCount}
              onChange={handleInputChange}
              className={`w-full p-2 border rounded ${errors.colliCount ? 'border-red-500' : 'border-gray-300'}`}
              placeholder="Number of boxes on pallet"
              min="0"
              max="200"
            />
            {errors.colliCount && (
              <p className="text-red-500 text-sm mt-1">{errors.colliCount}</p>
            )}
          </div>

          {/* Process Stage Selection */}
          <div className="form-group">
            <label className="block text-sm font-medium mb-1">Process Stage</label>
            <select
              name="processStage"
              value={formData.processStage}
              onChange={handleInputChange}
              className="w-full p-2 border rounded border-gray-300"
            >
              <option value="">Select Process Stage</option>
              {processStages.map(stage => (
                <option key={stage} value={stage}>
                  {stage.charAt(0).toUpperCase() + stage.slice(1).replace(/_/g, ' ')}
                </option>
              ))}
            </select>
          </div>

          {/* Order Assignment */}
          {availableOrders.length > 0 && (
            <div className="form-group">
              <label className="block text-sm font-medium mb-1">Assign to Order (Optional)</label>
              <select
                name="order_id"
                value={formData.order_id}
                onChange={handleInputChange}
                className="w-full p-2 border rounded border-gray-300"
              >
                <option value="">Leave Unassigned</option>
                {availableOrders.map(order => (
                  <option key={order.id} value={order.id}>
                    {order.customer_name} ({order.remaining_pallets} pallets needed)
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        {/* Action buttons */}
        <div className="flex justify-end space-x-4 mt-6">
          <button
            onClick={onCancel}
            className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
            disabled={loading}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={loading || Object.keys(errors).length > 0}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
          >
            {loading ? (
              <span className="flex items-center">
                <Loader className="animate-spin -ml-1 mr-2 h-4 w-4" />
                Processing...
              </span>
            ) : (
              initialData ? 'Update Pallet' : 'Add Pallet'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PalletForm;